import {useAclContext} from "../../../contexts/acl";
import {useChangeUserStatus} from "../../../services/react-query/user";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {init} from "i18next";
import {acceptUserOperations} from "../../../constants/operation";


const useUsersList = () => {


    const navigate = useNavigate()
    const { permissions } = useAclContext()
    const hasWriteAccess = permissions.user?.write


    const onOptionClicked = (idx, user) => {
        let action;
        if (idx === 0) {
            if (user?.verifyAt) action = 'reject'
            else action = 'accept'
        }else {
            action = acceptUserOperations[idx]
        }
        // navigate(`/users/${user?._id}`, {state: {action}})
        navigate(`/users/${user?._id}/setting`)
    }

    const onDetailsClicked = (user) => {
        navigate(`/users/${user?._id}`)
    }

    return {
        hasWriteAccess,
        onOptionClicked,
        onDetailsClicked
    }
}

export default useUsersList
