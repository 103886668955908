import styled from 'styled-components'
import { CFlex, Column, Flex, Row } from '../../../../styles/CommonStyles'
import Text from '../../../../core/utils/Text'
import { ScaleLoader } from 'react-spinners'

const UserStatsTable = ({ data }) => {
	if (!data)
		return (
			<Flex fw height={'300px'}>
				<ScaleLoader color={'#9F9181'} height={26} width={3} />
			</Flex>
		)

	return (
		<CFlex fw>
			{data?.map((item) => (
				<UserStatsRow cs={CS}>
					<Column>
						<Text tid={item.name} />
					</Column>
					<Column>
						{Array.isArray(item.value) ? (
							item.value.map((subItem, index) => {
								const { count, age, county, city, status, min } = subItem
								return (
									<Badge key={index}>
										<div className='mx-1'>
											{county} {age} {city} {status} {min}
										</div>{' '}
										: <div className='mx-1' style={{fontWeight: 'bold'}}>{count} </div>
									</Badge>
								)
							})
						) : (
							<div>{item.value}</div>
						)}{' '}
					</Column>
				</UserStatsRow>
			))}
		</CFlex>
	)
}

const CS = '50% 50%'

const Badge = styled.div`
	border-radius: 1rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.5px 1rem;
	background-color: ${(props) => props.theme.mainBg};
	color: ${(props) => props.theme.bg};
	margin: 4px 0;
`
const UserStatsRow = styled(Row)`
	padding: 10px;
	min-height: 32px;
`

export default UserStatsTable
