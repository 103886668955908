import SettingCard from '../../components/layout/main/SettingCard'
import ListLayout from '../../components/layout/main/ListLayout'
import TradesPanel from '../../components/main/panel/trades/TradesPanel'
import OrdersPanel from '../../components/main/panel/orders/OrdersPanel'
import { CFlex } from '../../styles/CommonStyles'
import UserPieCharts from '../../components/main/panel/users/UserPieCharts'
import PriceTimeChart from '../../components/main/panel/price-time/PriceTimeChart'
import VolumeWageChart from '../../components/main/panel/price-time/VolumeWageChart'
import VolumeWageCompareChart from '../../components/main/panel/price-time/VolumeWageCompareChart'

const Dashboard = () => {
	return (
		<ListLayout>
			<CFlex fw>
				<UserPieCharts />

				<PriceTimeChart />

				<VolumeWageChart />

				<VolumeWageCompareChart />

				<SettingCard style={{ minWidth: '300px', padding: '10px' }} noTitle main>
					<TradesPanel />
				</SettingCard>
				<SettingCard style={{ minWidth: '300px', padding: '10px' }} noTitle main>
					<OrdersPanel />
				</SettingCard>
			</CFlex>
		</ListLayout>
	)
}

export default Dashboard
