import { ScaleLoader } from "react-spinners"
import styled from "styled-components"
import Text from "../../../core/utils/Text"


const BasicModalActionButton = ({
    onSubmit,
    onCancel,
    loading
}) => {


    return (
        <Wrapper>
            <Button
                submit
                onClick={onSubmit}
            >
                {loading ?
                    <ScaleLoader
                        color={'#fafafa'}
                        height={18}
                        width={2}
                    />
                    :
                    <Text tid='submit' />
                }
            </Button>
            <Button
                onClick={onCancel}
            >
                <Text tid='cancel' />
            </Button>
        </Wrapper>
    )
}


const Wrapper = styled.div`
    display: flex;
    justify-content: space-evenly;
    height: 52px;
    width: 100%;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
`

const Button = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    color: ${props => props.theme.primary};
    transition: all 0.275s;
    border: 1px solid ${props => props.theme.color}10;
    margin: 0 5px;
    border-radius: 5px;

    &:hover{
        background-color: ${props => props.submit ? props.theme.mainGreen : props.theme.exitRed}90;
        width: 75%;
    };
`

export default BasicModalActionButton
