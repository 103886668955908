import styled, {keyframes} from "styled-components";
import { TiArrowSortedUp, TiArrowSortedDown } from 'react-icons/ti'

export const NoAccessText = styled.div`

  color: ${props => props.theme.primary};
  position: absolute;
  margin: auto;
  bottom: 40px;
  
  @media screen and (max-width: 768px) {

  };
`

const Animation = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
`

export const NoAccessDesc = styled.div`

  color: ${props => props.theme.active};
  position: absolute;
  margin: auto;
  bottom: 20px;
  
  animation: ${Animation} 1s infinite;

  @media screen and (max-width: 768px) {

  };
`


export const SortUp = styled(TiArrowSortedUp)`
  color: ${props => props.active ? props.theme.mainOrange : props.theme.color};
  margin-bottom: -3px;
`

export const SorDown = styled(TiArrowSortedDown)`
  color: ${props => props.active ? props.theme.mainOrange : props.theme.color};
  margin-top: -3px;
`
